export const CAT_NECESSARY = "necessary";
export const CAT_ANALYTICS = "analytics";
export const CAT_ADVERTISEMENT = "advertisement";
export const CAT_FUNCTIONALITY = "functionality";
export const CAT_SECURITY = "security";

export const SERVICE_AD_STORAGE = "ad_storage";
export const SERVICE_AD_USER_DATA = "ad_user_data";
export const SERVICE_AD_PERSONALIZATION = "ad_personalization";
export const SERVICE_ANALYTICS_STORAGE = "analytics_storage";
export const SERVICE_FUNCTIONALITY_STORAGE = "functionality_storage";
export const SERVICE_PERSONALIZATION_STORAGE = "personalization_storage";
export const SERVICE_SECURITY_STORAGE = "security_storage";
