// Called when the consent modal is shown - add padding to the top or bottom of the
// page depending on the configuration of the modal
let $body = document.getElementsByTagName("body")[0];
let bodyStyle = $body.currentStyle || window.getComputedStyle($body);
let bodyPaddingTop = bodyStyle.paddingTop;
let bodyPaddingBottom = bodyStyle.paddingBottom;

function setBodyPadding() {
    let cookieMessage = document.querySelector(".cm-wrapper .cm--bar");
    if (!cookieMessage) {
        return;
    }

    let cookieBarHeight = cookieMessage.offsetHeight || cookieMessage.getBoundingClientRect().height;
    if (cookieMessage.classList.contains("cm--bottom")) {
        $body.style.paddingBottom = cookieBarHeight + "px";
    } else if (cookieMessage.classList.contains("cm--top")) {
        $body.style.paddingTop = cookieBarHeight + "px";
    }
}

// Called when the consent modal is hidden - removes the additional padding
function removeBodyPadding() {
    let cookieMessage = document.querySelector(".cm-wrapper .cm--bar");
    if (!cookieMessage) {
        return;
    }

    if (cookieMessage.classList.contains("cm--bottom")) {
        $body.style.paddingBottom = bodyPaddingBottom;
        $body.style.transition = "padding-bottom ease 0.5s";
    } else if (cookieMessage.classList.contains("cm--top")) {
        $body.style.paddingTop = bodyPaddingTop;
        $body.style.transition = "padding-top ease 0.5s";
    }
}

// Setup and configuration of helpers requires that 'CookieConsent' object be available
export default function initBodyResize(CookieConsent) {
    if (typeof CookieConsent == "undefined") {
        console.warn("CookieConsent Helpers - CookieConsent is not defined");
    } else {
        window.addEventListener("cc:onModalShow", ({ detail }) => {
            if (detail.modalName === "consentModal") {
                window.addEventListener("resize", setBodyPadding);
                setBodyPadding();
            }
        });

        window.addEventListener("cc:onModalHide", ({ detail }) => {
            if (detail.modalName === "consentModal") {
                window.removeEventListener("resize", setBodyPadding);
                removeBodyPadding();
            }
        });
    }
}
